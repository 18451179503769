import { StarFilledIcon } from '@radix-ui/react-icons'
import { Fragment, useEffect, useState } from 'react'
import { cn } from '~/utils/misc.tsx'

export type StarsRatingProps = {
  id?: string
  value?: number | string
  defaultValue?: number | string
  size?: 'sm' | 'md' | 'lg'
  className?: string
  editable?: boolean
  onChange?: (value: number) => void
}

export const StarsRating = ({
  value: propValue,
  defaultValue,
  size = 'md',
  className,
  editable = false,
  onChange,
  id,
}: StarsRatingProps) => {
  const ensureNoNaN = (value: any) => {
    const num = Number(value)
    return isNaN(num) ? undefined : num
  }
  const [hoverValue, setHoverValue] = useState(
    ensureNoNaN(propValue) ?? ensureNoNaN(defaultValue) ?? 0,
  )
  const [value, setValue] = useState(
    ensureNoNaN(propValue) ?? ensureNoNaN(defaultValue) ?? 0,
  )

  // this useEffect is to fix a bug that happened sometimes that the stars din't set correctly
  useEffect(() => {
    const initialValue =
      ensureNoNaN(propValue) ?? ensureNoNaN(defaultValue) ?? 0
    if (initialValue !== hoverValue) {
      setHoverValue(initialValue)
    }
    if (initialValue !== value) {
      setValue(initialValue)
    }
  }, [propValue, defaultValue])

  const options: Record<typeof size, string> = {
    sm: 'h-4 w-4',
    md: 'h-5 w-5',
    lg: 'h-7 w-7',
  }
  const sizeClassName = options[size]

  const handleChange = (count: number) => {
    setValue(count)
    setHoverValue(count)
    if (onChange) onChange(count)
  }

  return (
    <div className="flex" id={id}>
      {[1, 2, 3, 4, 5].map((count, index) => (
        <Fragment key={count}>
          {editable ? (
            <StarFilledIcon
              onPointerOver={() => setHoverValue(count)}
              onPointerLeave={() => setHoverValue(value)}
              onClick={() => handleChange(count)}
              className={cn(
                `text-gray-200 ${sizeClassName}`,
                className,
                index < hoverValue ? 'text-yellow-400' : '',
              )}
            />
          ) : (
            <StarFilledIcon
              className={cn(
                `text-gray-200 ${sizeClassName}`,
                className,
                index < Math.round(value) ? 'text-yellow-400' : '',
              )}
            />
          )}
        </Fragment>
      ))}
    </div>
  )
}
